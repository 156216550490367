import React from 'react';
import { graphql } from 'gatsby';

import SEO from '@components/seo';

import Layout from '@components/Layout/Layout';

import Container from '@components/Container';
import Grid, { Row } from '@components/Grid';
import TextHero from '@components/TextHero';
import UspList from '@components/UspList/UspList';

import {
  ContentfulCard,
  ContentfulDialogContent,
  ContentfulQuestion,
  ContentfulTextList,
} from '../../graphql-types';
import Card, { CardImage } from '@src/components/Card';
import useDialogRoute from '@src/components/Dialog/useDialogRoute.hook';
import ContentfulDialog from '@src/components/Dialog/ContentfulDialog';
import Illustration from '@src/components/Illustration/Illustration';
import useContentfulImage from '@src/hooks/useContentfulImage.hook';

const Pay = ({ data }) => {
  const {
    contentfulPage: {
      pageHeader,
      pageHeaderDescription: { pageHeaderDescription },
      pageContent,
    },
  } = data;

  const uspList = pageContent[0] as ContentfulTextList;

  const rockerPayCard = pageContent[1] as ContentfulCard;
  const rockerPayImage = useContentfulImage(rockerPayCard.backgroundImage);

  const blocketCard = pageContent[2] as ContentfulCard;
  const plickDialogData = pageContent[3] as ContentfulDialogContent;
  const blocketDialog = useDialogRoute('plick');

  const plickCard = pageContent[4] as ContentfulCard;
  const blocketDialogData = pageContent[5] as ContentfulDialogContent;
  const plickDialog = useDialogRoute('blocket');

  const { questions, questionsHeading } = data.contentfulPage;
  const headLine = data.contentfulPage.headLine;

  return (
    <Layout
      path="/lan"
      theme="soft"
      questions={questions as ContentfulQuestion[]}
      questionsHeading={questionsHeading}
    >
      <SEO title={headLine} />
      <Container style={{ paddingBottom: 68, paddingTop: 32 }}>
        <Grid>
          <div style={{ maxWidth: 448 }}>
            <TextHero
              leftAligned
              size="medium"
              title={pageHeader}
              subtitle={pageHeaderDescription}
              style={{ paddingTop: 32, paddingBottom: 32 }}
            >
              <UspList items={uspList.advancedTextListItems} />
            </TextHero>
          </div>
          <Card
            title={rockerPayCard.title}
            text={rockerPayCard.subtitle}
            ctaHref={rockerPayCard.linkPath}
            cta={rockerPayCard.linkText}
            backdropImage={rockerPayImage}
            theme="purple"
          ></Card>
          <Card
            title={blocketCard.title}
            text={blocketCard.subtitle}
            cta={blocketCard.linkText}
            onClick={blocketDialog.openDialog}
            theme="white"
          >
            <CardImage>
              <Illustration
                name="PlickPill"
                block
                size="68%"
                style={{
                  marginTop: '6%',
                }}
              />
            </CardImage>
          </Card>
          <Card
            title={plickCard.title}
            text={plickCard.subtitle}
            cta={plickCard.linkText}
            onClick={plickDialog.openDialog}
            theme="white"
          >
            <CardImage>
              <Illustration
                name="BlocketPill"
                block
                size="68%"
                style={{
                  marginTop: '6%',
                }}
              />
            </CardImage>
          </Card>
        </Grid>
      </Container>

      <ContentfulDialog
        contentfulData={plickDialogData}
        visible={blocketDialog.isOpen}
        onClose={blocketDialog.closeDialog}
      />
      <ContentfulDialog
        contentfulData={blocketDialogData}
        visible={plickDialog.isOpen}
        onClose={plickDialog.closeDialog}
      />
    </Layout>
  );
};

export default Pay;

export const query = graphql`
  query Pay($locale: String) {
    contentfulPage(pageUrl: { eq: "/pay" }, node_locale: { eq: $locale }) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
        childMarkdownRemark {
          html
        }
      }
      questionsHeading
      questions {
        answer {
          answer
        }
        question
      }
      pageContent {
        ... on ContentfulCard {
          additionalLinkPath
          additionalLinkText
          linkPath
          linkText
          title
          subtitle
          backgroundImage {
            gatsbyImageData(width: 1040)
            title
          }
        }
        ... on ContentfulCardsSectionComponent {
          entryTitle
          card {
            additionalLinkPath
            additionalLinkText
            subtitle
            title
            linkText
            linkPath
          }
        }
        ... on ContentfulDialogContent {
          id
          name
          heading
          textContent {
            textContent
          }
          ctaText
          ctaHref
          extraContent {
            ... on ContentfulTextList {
              advancedTextListItems {
                text
                icon
              }
              listName
            }
          }
          questions {
            answer {
              answer
            }
            question
          }
        }
        ... on ContentfulGenericList {
          name
          items {
            icon {
              file {
                url
              }
              title
            }
            extraText
            longText {
              longText
            }
            title
            text
          }
        }
        ... on ContentfulTextList {
          id
          listName
          advancedTextListItems {
            icon
            text
          }
        }
      }
      pageContactHeader
      customContactMethods {
        name
        methods {
          link
          linkText
          title
          extraLinkText
          extraLink
          icon {
            file {
              url
            }
          }
          description {
            description
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
